import React from "react";
import { siteWidth } from "~/utils/variables";

interface Props {
  children: React.ReactNode;
}

export const Container: React.FC<Props> = ({ children }) => {
  return (
    <>
      <style jsx>{`
        .container {
          display: flex;
          margin: 0 auto;
          max-width: ${siteWidth.default};
        }
      `}</style>
      <div className="container">{children}</div>
    </>
  );
};
