import React from "react";
import { colors } from "~/utils/colors";
import { fontFamily, breakPoints } from "~/utils/variables";

export const InitStyle: React.FC = () => (
  <style jsx global>{`
    @font-face {
      font-family: "Metric";
      font-style: normal;
      font-weight: 400;
      src: url("/fonts/metric-web-regular.woff2"), url("/fonts/metric-web-regular.woff"),
        url("/fonts/metric-web-regular.eot");
    }
    @font-face {
      font-family: "Metric";
      font-style: italic;
      font-weight: 400;
      src: url("/fonts/metric-web-regular-italic.woff2"), url("/fonts/metric-web-regular-italic.woff"),
        url("/fonts/metric-web-regular-italic.eot");
    }
    @font-face {
      font-family: "Metric";
      font-style: normal;
      font-weight: 500;
      src: url("/fonts/metric-web-medium.woff2"), url("/fonts/metric-web-medium.woff"),
        url("/fonts/metric-web-medium.eot");
    }
    @font-face {
      font-family: "Metric";
      font-style: italic;
      font-weight: 500;
      src: url("/fonts/metric-web-medium-italic.woff2"), url("/fonts/metric-web-medium-italic.woff"),
        url("/fonts/metric-web-medium-italic.eot");
    }
    body {
      background-color: ${colors.white};
      color: ${colors.defaultText};
      font-family: ${fontFamily.default};
      font-size: 15px;
      line-height: 1.8;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    a,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
    }
    ul {
      list-style: none;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    dt,
    th,
    b {
      font-weight: 500;
    }
    body main {
      position: relative;
      width: 100%;
    }
    p span,
    h1 span,
    h2 span,
    h3 span {
      display: inline-block;
    }
    br.spVisible,
    div.spVisible,
    p.spVisible {
      display: none;
    }
    br.pcVisible {
      display: inline;
    }
    div.pcVisible,
    p.pcVisible {
      display: block;
    }
    @media (${breakPoints.sp}) {
      br.spVisible {
        display: inline;
      }
      div.spVisible,
      p.spVisible {
        display: block;
      }
      br.pcVisible,
      div.pcVisible,
      p.pcVisible {
        display: none;
      }
    }
  `}</style>
);
