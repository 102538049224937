import React from "react";
import { Link } from "gatsby";
import rightArrow from "../images/inline_svg/right_arrow.svg";
import { Container } from "~/atoms/Container";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

export interface BreadCrumb {
  name: string;
  to?: string | undefined;
}

interface Props {
  bgColor?: string;
  items?: BreadCrumb[];
}

export const BreadCrumbs: React.FC<Props> = ({ bgColor = colors.white, items = [] }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          background-color: ${bgColor || colors.white};
          margin: 0;
          padding: 92px 30px 0;
        }

        .breadcrumbList {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
        }

        .breadcrumbListItem {
          position: relative;
        }

        .breadcrumbList :global(a) {
          padding-right: 20px;
          position: relative;
        }

        .breadcrumbList :global(a:link),
        .breadcrumbList :global(a:visited),
        .breadcrumbList :global(a:active) {
          color: ${colors.orange};
        }

        .breadcrumbList :global(a:hover span) {
          text-decoration: underline;
        }

        .breadcrumbListItemName {
          font-size: 11px;
          font-weight: 500;
        }

        .breadcrumbListItemArrow {
          position: absolute;
          height: 9px;
          right: 8px;
          top: 10px;
        }

        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 72px 20px 0;
          }

          .breadcrumbs {
            overflow: hidden;
            position: relative;
          }

          .breadcrumbList {
            flex-wrap: nowrap;
            height: 27px;
            overflow: hidden;
            position: relative;
            white-space: nowrap;
            width: 100%;
          }

          .breadcrumbList:before {
            background: ${bgColor || colors.white};
            color: ${colors.defaultText};
            content: "...";
            display: block;
            bottom: 0;
            top: 0;
            right: 0;
            padding-left: 5px;
            position: absolute;
            z-index: 1;
          }
        }
      `}</style>
      <div className="wrapper">
        <Container>
          <nav className="breadcrumbs">
            <ol className="breadcrumbList">
              {items.map((item, index) => {
                const isLast = index === items.length - 1;

                return (
                  <li key={`BreadCrumbItem_${index}`} className="breadcrumbListItem">
                    {isLast ? (
                      <span className="breadcrumbListItemName">{item.name}</span>
                    ) : (
                      <>
                        {item.to && (
                          <>
                            <Link to={item.to}>
                              <span className="breadcrumbListItemName">{item.name}</span>
                            </Link>
                            <img src={rightArrow} className="breadcrumbListItemArrow" />
                          </>
                        )}
                      </>
                    )}
                  </li>
                );
              })}
            </ol>
          </nav>
        </Container>
      </div>
    </>
  );
};
