import "ress/ress.css";
import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { Header } from "~/organisms/Header";
import { Footer } from "~/organisms/Footer";
import { BreadCrumb, BreadCrumbs } from "~/organisms/BreadCrumbs";
import { InitStyle } from "~/atoms/InitStyle";
import ogpPng from "~/images/ogp.png";
import "~/utils/styledJsx";

type Props = {
  title?: string;
  children: React.ReactNode;
  ogpDescription?: string;
  ogpImageUrl?: string;
  ogpTitle?: string;
  noindex?: boolean;
  canonicalUrl?: string;
  isTopPage?: boolean;
  hideBreadCrumbs?: boolean;
  breadCrumbItems?: BreadCrumb[];
  breadCrumbBgColor?: string;
};

export const Layout: React.FC<Props> = ({
  title,
  children,
  ogpDescription,
  ogpImageUrl,
  ogpTitle,
  noindex,
  canonicalUrl,
  isTopPage = false,
  hideBreadCrumbs = false,
  breadCrumbItems,
  breadCrumbBgColor,
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

  const pageTitle = title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title;
  const sharedOgpTitle = ogpTitle ? ogpTitle : pageTitle;
  const sharedOgpDescription = ogpDescription || site.siteMetadata.description;
  const meta = [
    {
      name: `description`,
      content: sharedOgpDescription,
    },
    {
      property: `og:title`,
      content: sharedOgpTitle,
    },
    {
      property: `og:description`,
      content: sharedOgpDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: ogpImageUrl ? ogpImageUrl : `${ogpPng}`,
    },
    {
      property: `fb:app_id`,
      content: `2220166827995808`,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: sharedOgpTitle,
    },
    {
      name: `twitter:description`,
      content: ogpDescription,
    },
    {
      name: `twitter:image`,
      content: ogpImageUrl ? ogpImageUrl : `${ogpPng}`,
    },
    {
      httpEquiv: `content-language`,
      content: "ja",
    },
    noindex ? { name: `robots`, content: `noindex` } : {},
  ];
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "ja",
        }}
        meta={meta}
      >
        <title>{pageTitle}</title>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      </Helmet>
      <InitStyle />
      <Header isTopPage={isTopPage} />
      {!isTopPage && !hideBreadCrumbs && <BreadCrumbs items={breadCrumbItems} bgColor={breadCrumbBgColor} />}
      <main>{children}</main>
      <Footer />
    </>
  );
};
